/* eslint-disable object-curly-newline */
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import usePermissions from '@/hooks/permissions';
import formatPrice from '@/utils/formatPrice';
import getIdFromIri from '@/utils/getIdFromIri';
import { formatDate } from '@/utils/date-utils';

export default function useEntityFields() {
  const { t } = useI18n();
  const router = useRouter();
  const { isSuperAdmin } = usePermissions();

  const ellipsis = (val) => (val.length > 10 ? `${val.slice(0, 10)}...` : val);
  const priceFilter = formatPrice;

  return {
    company: ref([
      { key: 'name', sortable: true, label: t('general.field.name') },
      { key: '_actions', actions: ['edit', 'delete'] },
    ]),

    user: ref([
      { key: 'email', sortable: true, label: t('user.email.label') },
      { key: 'roles', sortable: false, label: t('user.role.label') },
      { key: 'company.name', sortable: true, label: t('company.label'), visible: isSuperAdmin },
      { key: '_actions', actions: ['edit', 'delete'] },
    ]),

    stores: ref([
      { key: 'name', sortable: true, label: t('general.field.name') },
      { key: '_actions', actions: ['detail', 'edit', 'delete'], actionOptions: (e) => ({ detail: e['@id'] }) },
    ]),
    storeLayout: ref([
      { key: 'name', sortable: true, label: t('general.field.name') },
      { key: 'tables', sortable: false, label: t('storeLayout.tableAmount') },
      {
        key: '_actions',
        actions: ['detail', 'delete'],
        actionOptions: (e) => ({
          detail: router.resolve({ name: 'store-store-layouts-id', params: { storeLayoutId: getIdFromIri(e['@id']) } }),
        }),
      },
    ]),
    register: ref([
      { key: 'name', sortable: true, label: t('general.field.name') },
      { key: 'store.name', sortable: true, label: t('store.label') },
      { key: 'lastSync', sortable: false, label: t('register.lastSync.label'), displayFilter: (v) => formatDate(v, 'dd.MM.yyyy HH:mm:ss') },
      { key: 'lastHeartbeat', sortable: false, label: t('register.lastHeartbeat.label'), displayFilter: (v) => formatDate(v, 'dd.MM.yyyy HH:mm:ss') },
      { key: 'invoiceIdentifier', sortable: false, label: t('register.invoiceIdentifier.label') },
      { key: 'orderCalloutNumberRangeStart', sortable: false, label: t('register.orderCalloutNumberRangeStart.label') },
      { key: 'orderCalloutNumberRangeEnd', sortable: false, label: t('register.orderCalloutNumberRangeEnd.label') },
      {
        key: '_actions',
        actions: ['detail', 'delete'],
        actionOptions: (e) => ({ detail: router.resolve({ name: 'store-registers-id', params: { registerId: getIdFromIri(e['@id']) } }) }),
      },
    ]),
    registerLayout: ref([
      { key: 'name', sortable: true, label: t('general.field.name') },
      { key: '_actions', actions: ['detail', 'delete'], actionOptions: (e) => ({ detail: e['@id'] }) },
    ]),
    sidebarMenu: ref([
      { key: 'name', sortable: true, label: t('general.field.name') },
      { key: '_actions', actions: ['detail', 'delete'], actionOptions: (e) => ({ detail: e['@id'] }) },
    ]),
    printer: ref([
      { key: 'name', sortable: true, label: t('general.field.name') },
      { key: 'ipAddress', sortable: false, label: t('general.field.ipAddress') },
      { key: '_actions', actions: ['edit', 'delete'] },
    ]),
    printerRule: ref([
      { key: 'printer.name', sortable: true, label: t('printer.label') },
      { key: 'productGroup.name', sortable: true, label: t('productGroup.label') },
      { key: 'register.name', sortable: true, label: t('register.label') },
      { key: '_actions', actions: ['edit', 'delete'] },
    ]),
    scale: ref([
      { key: 'name', sortable: true, label: t('general.field.name') },
      { key: 'ipAddress', sortable: false, label: t('general.field.ipAddress') },
      { key: '_actions', actions: ['edit', 'delete'] },
    ]),
    paymentTerminal: ref([
      { key: 'name', sortable: true, label: t('general.field.name') },
      { key: 'ipAddress', sortable: false, label: t('general.field.ipAddress') },
      { key: '_actions', actions: ['edit', 'delete'] },
    ]),
    customerScanner: ref([
      { key: 'deviceName', sortable: true, label: t('general.field.name') },
      { key: 'supportedIdentifierTypes', sortable: false, label: t('customerIdentifier.type.label', 2) },
      { key: '_actions', actions: ['edit', 'delete'] },
    ]),

    shift: ref([
      { key: 'register.name', sortable: true, label: t('register.label') },
      { key: 'start', sortable: true, label: t('general.field.start'), displayFilter: (v) => formatDate(v, 'dd.MM.yyyy HH:mm:ss') },
      { key: 'end', sortable: true, label: t('general.field.end'), displayFilter: (v) => formatDate(v, 'dd.MM.yyyy HH:mm:ss') },
      {
        key: '_actions',
        actions: ['detail'],
        actionOptions: (e) => ({ detail: router.resolve({ name: 'store-shifts-shift-id', params: { shiftId: getIdFromIri(e['@id']) } }) }),
      },
    ]),
    shiftShort: ref([
      { key: 'start', sortable: true, label: t('general.field.start'), displayFilter: (v) => formatDate(v, 'dd.MM.yyyy HH:mm:ss') },
      { key: 'end', sortable: true, label: t('general.field.end'), displayFilter: (v) => formatDate(v, 'dd.MM.yyyy HH:mm:ss') },
      {
        key: '_actions',
        actions: ['detail'],
        actionOptions: (e) => ({ detail: router.resolve({ name: 'store-shifts-shift-id', params: { shiftId: getIdFromIri(e['@id']) } }) }),
      },
    ]),
    requestLogList: ref([
      { key: 'createdAt', sortable: true, label: t('requestLog.createdAt.label'), displayFilter: (v) => formatDate(v, 'dd.MM.yyyy HH:mm:ss') },
      { key: 'time', sortable: false, label: t('requestLog.time.label'), displayFilter: (v) => `${v}ms` },
      { key: 'code', sortable: false, label: t('requestLog.code.label') },
      { key: 'url', sortable: false, label: t('requestLog.url.label') },
      { key: 'method', sortable: false, label: t('requestLog.method.label') },
      {
        key: '_actions',
        actions: ['detail'],
        actionOptions: (e) => ({ detail: router.resolve({ name: 'register-request-logs-id', params: { id: getIdFromIri(e['@id']) } }) }),
      },
    ]),

    customer: ref([
      { key: 'number', sortable: true, label: t('customer.number.label'), searchFilter: { type: 'freeText', default: '' } },
      { key: 'name', sortable: true, label: t('general.field.name'), searchFilter: { type: 'freeText', default: '' } },
      { key: 'customerGroup', sortable: true, label: t('customerGroup.label'), displayFilter: (val) => (val.name) },
      { key: 'description', sortable: false, label: t('general.field.description') },
      { key: '_actions', actions: ['detail', 'edit', 'delete'], actionOptions: (e) => ({ detail: e['@id'] }) },
    ]),
    customerGroup: ref([
      { key: 'number', sortable: true, label: t('customerGroup.number.label'), searchFilter: { type: 'freeText', default: '' } },
      { key: 'name', sortable: true, label: t('general.field.name'), searchFilter: { type: 'freeText', default: '' } },
      { key: 'description', sortable: false, label: t('general.field.description'), displayFilter: ellipsis },
      {
        key: '_actions',
        actions: ['list', 'qr', 'edit', 'delete'],
        actionOptions: (e) => ({
          list: `/customers?customerGroup=${e['@id']}`,
        }),
      },
    ]),

    productGroup: ref([
      { key: 'name', sortable: true, label: t('general.field.name') },
      { key: '_actions', actions: ['edit', 'delete'] },
    ]),
    product: ref([
      { key: 'productNumber', sortable: true, label: t('product.number.label'), searchFilter: { type: 'freeText', default: '' } },
      { key: 'name', sortable: true, label: t('general.field.name'), searchFilter: { type: 'freeText', default: '' } },
      { key: 'price', sortable: false, label: t('price.label'), displayFilter: (val, entity) => (entity.pricingMode === 30 ? '' : priceFilter(val)) },
      { key: '_actions', actions: ['detail', 'delete'], actionOptions: (e) => ({ detail: e['@id'] }) },
    ]),
    subProductGroup: ref([
      { key: 'name', sortable: true, label: t('general.field.name'), searchFilter: { type: 'freeText', default: '' } },
      { key: 'minChoices', sortable: false, label: t('subProductGroup.minChoices.label') },
      { key: 'maxChoices', sortable: false, label: t('subProductGroup.maxChoices.label'), displayFilter: (val) => (val == null ? '∞' : val) },
      { key: 'title', sortable: false, label: t('general.field.title') },
      {
        key: '_actions', actions: ['detail', 'delete'], actionOptions: (e) => ({ detail: e['@id'] }),
      },
    ]),

    vatRate: ref([
      { key: 'name', sortable: true, label: t('general.field.name') },
      { key: 'value', sortable: false, label: t('general.field.value') },
      { key: '_actions', actions: ['edit', 'delete'] },
    ]),

    baseWeight: ref([
      { key: 'name', sortable: true, label: t('general.field.name') },
      { key: 'weight', sortable: false, label: t('baseWeight.weight.label'), displayFilter: (val) => `${val} g` },
      { key: '_actions', actions: ['edit', 'delete'] },
    ]),

    discount: ref([
      { key: 'name', sortable: true, label: t('general.field.name') },
      { key: 'applyToOrder', sortable: false, label: t('discount.applyToOrder.label') },
      { key: 'applyToSubProducts', sortable: false, label: t('discount.applyToSubProducts.label') },
      { key: 'cashierSelectable', sortable: false, label: t('discount.cashierSelectable.label') },
      { key: 'discountType', sortable: false, label: t('discount.type.label'), displayFilter: (val) => t(`discount.type.${val}`) },
      { key: 'discount', sortable: false, label: t('discount.label') },
      { key: '_actions', actions: ['edit', 'delete'] },
    ]),
    promotion: ref([
      { key: 'name', sortable: true, label: t('general.field.name') },
      { key: 'oncePerDay', label: t('promotion.oncePerDay') },
      {
        key: '_actions',
        actions: ['detail', 'delete'],
        actionOptions: (e) => ({ detail: router.resolve({ name: 'promotions-id', params: { id: getIdFromIri(e['@id']) } }) }),
      },
    ]),

    registerTheme: ref([
      { key: 'name', sortable: true, label: t('general.field.name') },
      { key: '_actions', actions: ['detail', 'delete'], actionOptions: (e) => ({ detail: e['@id'] }) },
    ]),

    image: ref([
      { key: 'name', sortable: true, label: t('general.field.name') },
      { key: 'contentUrl', sortable: false, label: t('image.label') },
      { key: '_actions', actions: ['edit', 'delete'] },
    ]),
    icon: ref([
      { key: 'name', sortable: true, label: t('general.field.name') },
      { key: 'contentUrl', sortable: false, label: t('icon.label') },
    ]),
  };
}
