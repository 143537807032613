import { ref } from 'vue';
import { merge } from 'lodash';
import axios from '@/services/axios';
import getIdFromIri from '@/utils/getIdFromIri';

export default function useLoadOptions(path, queryProp, fixedFilters = {}, hideDeleted = true) {
  const loading = ref(false);

  const loadOptions = async (query, initial = null, additionalFilters = {}) => {
    loading.value = true;
    let result = [];
    let params = merge({ ...fixedFilters, ...additionalFilters });

    if (query) {
      if (typeof queryProp === 'string') {
        params[queryProp] = query;
      } else if (Array.isArray(queryProp)) {
        queryProp.forEach((p) => {
          params[`search[${p}]`] = query;
        });
      }
    } else if (Array.isArray(initial) && initial.length) {
      params = {};
      params.id = initial.map((i) => getIdFromIri(i));
      params.pagination = false;
    } else if (!Array.isArray(initial) && initial != null) {
      params = {};
      params.id = getIdFromIri(initial);
    }

    if (hideDeleted) {
      params.hideDeleted = hideDeleted;
    }

    try {
      const { data } = await axios.get(path, { params });

      if (data['hydra:member']) {
        result = data['hydra:member'];
      }
    } catch (err) {
      console.error(err, err.message, err.response);
    }

    loading.value = false;
    return result;
  };

  return {
    loading,
    loadOptions,
  };
}
