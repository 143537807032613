<template>
  <div class="checkbox">
    <label>
      <input v-model="model" type="checkbox" :value="value">
      <span class="box" />
      {{ label }}
    </label>
    <small v-if="error" class="text-red" v-text="te(error) ? t(error, [label]) : error" />
  </div>
</template>

<script>
import slugify from 'slugify';
import { useI18n } from 'vue-i18n';
import { useModel } from '@/hooks/model';

export default {
  name: 'FancyCheckbox',
  props: {
    label: { type: String, required: true },
    modelValue: { type: [String, Number, Boolean], default: null },
    required: { type: Boolean, default: false },
    error: { type: String, default: null },
    value: { type: [String, Number, Boolean], default: true },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { t, te } = useI18n();
    const model = useModel(props, emit);
    const id = slugify(props.label);

    return {
      t,
      te,
      id,
      model,
    };
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  @apply relative;

  & > label {
    @apply relative pl-6 text-base;

    .box::before {
      @apply absolute left-0 top-1/2 transform -translate-y-1/2;
      @apply w-4 h-4 block border border-secondary rounded-sm;

      content: "";
    }

    .box::after {
      @apply absolute left-0 top-1/2 transform -translate-y-1/2;
      @apply w-4 h-4 block text-white bg-primary border border-primary rounded-sm;
      @apply opacity-0 transition-opacity duration-300 ease-in-out;
      @apply flex justify-center items-center;

      content: "✓";
    }

    & > input {
      @apply absolute;

      left: -99999px;

      &:checked + .box::after {
        @apply opacity-100;
      }
    }
  }
}
</style>
