<template>
  <div class="card">
    <slot name="action">
      <div v-if="action" class="absolute top-2 right-2 p-2">
        <button class="small" @click="$emit('click')" v-text="action" />
      </div>
    </slot>
    <component :is="bigHeading ? 'h1' : 'h2'" class="mt-0">
      {{ title }}
    </component>
    <hr>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
      <div
        v-for="field in fields"
        :key="entity.id + '_' + field"
        :class="{ 'md:col-span-2': field.colspan }"
      >
        <div class="label">
          {{ field.label }}
        </div>
        <div>
          <slot v-bind="{ entity }" :name="field.key">

            <template v-if="field.formatter">
              {{ field.formatter(get(entity, field.key)) }}
            </template>
            <template v-else-if="typeof get(entity, field.key) === 'boolean'">
              <checkmark-icon v-if="get(entity, field.key)" class="block w-6 h-6" />
              <close-icon v-else class="block w-6 h-6" />
            </template>
            <template v-else>
              {{ get(entity, field.key) }}
            </template>

          </slot>
        </div>
      </div>
    </div>
    <slot name="additional" />
  </div>
</template>

<script>
import { get } from 'lodash/object';
import CheckmarkIcon from '@/assets/images/checkmark.svg';
import CloseIcon from '@/assets/images/close.svg';

export default {
  name: 'EntityCard',
  components: {
    CheckmarkIcon,
    CloseIcon,
  },
  props: {
    fields: { type: Array, required: true },
    entity: { type: Object, required: true },
    title: { type: String, default: '' },
    action: { type: String, default: '' },
    bigHeading: { type: Boolean, default: false },
  },
  emits: ['click'],
  methods: {
    get(o, p) {
      return get(o, p);
    },
  },
};
</script>
