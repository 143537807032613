<template>
  <div>
    <label :for="id">
      <slot name="label">
        {{ label }}
      </slot>
      {{ required ? '*' : '' }}
    </label>
    <select
      :id="id"
      v-model="value"
      :required="required"
      :name="id"
    >
      <option
        v-for="option in options"
        :key="option.key"
        :value="option.value"
        v-text="option.label"
      />
      <slot />
    </select>
    <small v-if="error" class="text-red" v-text="te(error) ? t(error, [label]) : error" />
  </div>
</template>

<script>
import slugify from 'slugify';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'SimpleDropdown',
  props: {
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number, Array],
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { t, te } = useI18n();
    const id = slugify(props.label);
    const value = computed({
      get: () => props.modelValue,
      set: (val) => emit('update:modelValue', val),
    });

    return {
      t,
      te,
      id,
      value,
    };
  },
};
</script>
