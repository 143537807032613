export const TABLE_SHAPES = {
  SHAPE_RECTANGULAR: 10,
  SHAPE_ROUND: 20,
};

export const VAT_RATE_TYPES = {
  VAT_RATE_TYPE_INHOUSE: 10,
  VAT_RATE_TYPE_TO_GO: 20,
  SPECIAL_TYPE_REPRINT_LAST_TICKET: 100,
  SPECIAL_TYPE_OPEN_DRAWER: 110,
};

export const BUTTON_TYPES = {
  BUTTON_TYPE_EMPTY: 0,
  BUTTON_TYPE_PRODUCT: 10,
  BUTTON_TYPE_GROUP: 20,
  BUTTON_TYPE_REGISTER_LAYOUT: 30,
};

export const PRODUCT_TYPES = {
  PRODUCT_TYPE_NORMAL: 10,
  PRODUCT_TYPE_RECHARGE_BALANCE: 20,
};

export const PRODUCT_PRICING_MODES = {
  PRICING_MODE_FIX: 10,
  PRICING_MODE_WEIGHT: 20,
  PRICING_MODE_VARIABLE: 30,
};

export const CUSTOMER_RECEIPT_PRINT_MODE = {
  RECEIPT_PRINT_ALWAYS: 10,
  RECEIPT_PRINT_ASK: 20,
  RECEIPT_PRINT_NEVER: 30,
};

export const CUSTOMER_IDENTIFIER_TYPES = {
  QR: 10,
};

export const USER_ROLES = [
  'ROLE_SUPER_ADMIN',
  'ROLE_ADMIN',
  'ROLE_PRODUCT_MANAGER',
  'ROLE_STATISTICS',
  'ROLE_USER',
];

export const CUSTOMER_GROUP_EXPORT_TYPES = {
  TYPE_QR: 'qr',
};

export const REGISTER_THEME_COLORS = [
  'color1',
  'color2',
  'color3',
  'color4',
  'color5',
  'color6',
  'color7',
  'color8',
  'splashScreenBackground',
  'splashScreenTileText',
  'splashScreenTitleText',
  'splashScreenTileBackground',
  'splashScreenTileBorder',
  'correctionButtonBackground',
  'correctionButtonText',
  'correctionButtonActiveBackground',
  'correctionButtonActiveText',
  'sidebarBackground',
  'separatorLineColor',
  'sidebarText',
  'sidebarPlusButtonBackground',
  'sidebarMinusButtonBackground',
  'sidebarMinusInactiveButtonBackground',
  'sidebarDeleteButtonBackground',
  'sidebarPlusButtonText',
  'sidebarMinusButtonText',
  'sidebarMinusInactiveButtonText',
  'sidebarDeleteButtonText',
  'payButtonBackground',
  'payButtonInactiveBackground',
  'payButtonText',
  'payButtonInactiveText',
  'promoButtonBackground',
  'promoButtonText',
  'mainBackground',
  'navigationButtonBackground',
  'navigationButtonText',
  'currentLocationText',
  'tilesBackground',
  'tilesText',
  'tilesImage',
  'productModificationBackground',
  'productModificationTitleText',
  'productModificationPriceText',
  'modificationCloseButtonBackground',
  'modificationCloseButtonText',
  'modifierTitleText',
  'modifierSelectionBackground',
  'selectedModifier',
  'nonselectedModifier',
  'mandatoryModifierText',
  'mandatoryModifierSelectedText',
  'optionalModifierText',
  'optionalModifierDisabledText',
  'modifierIncreaseButtonBackground',
  'modifierIncreaseInactiveButtonBackground',
  'modifierDecreaseButtonBackground',
  'modifierDecreaseInactiveButtonBackground',
  'modifierIncreaseButtonText',
  'modifierIncreaseInactiveButtonText',
  'modifierDecreaseButtonText',
  'modifierDecreaseInactiveButtonText',
  'modifierAddButtonBackground',
  'modifierAddButtonText',
  'weightDetailBackground',
  'weightDetailTitleText',
  'weightDetailSubtitleText',
  'weightDetailAmountText',
  'weightDetailErrorText',
  'weightDetailPriceText',
  'weightSelectionBackground',
  'weightSelectionTileBackground',
  'weightSelectionTileText',
  'weightSelectionTileSelectedBackground',
  'weightSelectionTileSelectedText',
  'weightSelectionScaleImage',
  'weightCloseButtonBackground',
  'weightCloseButtonText',
  'weightAddButtonBackground',
  'weightAddButtonText',
  'weightAddButtonDisabledBackground',
  'weightAddButtonDisabledText',
  'qrScannerBackground',
  'qrScannerTitleText',
  'qrScannerSubtitleText',
  'qrScannerCloseButtonBackground',
  'qrScannerCloseButtonText',
  'clearAlertBackground',
  'clearAlertTitle',
  'clearAlertMessage',
  'clearAlertXButtonBackground',
  'clearAlertXButtonForeground',
  'clearAlertAbortButtonBackground',
  'clearAlertAbortButtonText',
  'clearAlertCloseButtonBackground',
  'clearAlertCloseButtonText',
  'defaultTilesBottomColor',
  'navigationBarBackground',
  'navigationBarMenuActiveText',
  'navigationBarMenuInactiveText',
  'submenuBackground',
  'submenuSelectionText',
  'submenuInactiveText',
  'submenuSeparatorLine',
  'submenuActiveLine',
  'switchBackground',
  'switchInactiveText',
  'switchActiveText',
  'switchActiveBackground',
  'servedTableSelectionBackground',
  'servedActiveTable',
  'servedActiveTableText',
  'servedInactivetiveTable',
  'servedInactivetiveTableText',
  'servedTableBorderColor',
  'servedTableShadowColor',
  'servedSelectedTableBackground',
  'servedSelectedTableTitle',
  'tableOrderActiveBackground',
  'tableOrderActiveTitle',
  'tableOrderInactiveBackground',
  'tableOrderInactiveTitle',
  'tableOrderProductAmountBackground',
  'tableOrderProductAmount',
  'tableOrderProductName',
  'tableOrderProductPrice',
  'tableOrderProductDiscount',
  'tableOrderProductAdvanced',
  'tableOrderProductAdvancedButton',
  'tableOrderProductAdvancedButtonTitle',
  'tableOrderProductAdvancedButtonInactive',
  'tableOrderProductAdvancedButtonInactiveTitle',
  'tableOrderProductAdvancedButtonSeparator',
  'tableOrderTotalSeparator',
  'tableOrderTotal',
  'tableOrderTotalAmount',
  'tableOrderCredit',
  'tableOrderCreditAmount',
  'tableOrderPreorderBackground',
  'tableOrderPreorderText',
  'tableOrderSplitBackground',
  'tableOrderSplitText',
  'paymentAmountBackground',
  'paymentAmountTitle',
  'paymentAmountSumm',
  'paymentAmountGiven',
  'paymentAmountGivenBorder',
  'paymentAmountReturn',
  'paymentAmountTip',
  'paymentHeaderBackground',
  'paymentBackground',
  'paymentNoteBorder',
  'paymentCalculatorButtonBackground',
  'paymentCalculatorButtonText',
  'paymentCalculatorClearBackground',
  'paymentCalculatorClearText',
  'paymentCalculatorBorder',
];
